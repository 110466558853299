<template>
  <div class="surveyResult-container">
    <form-panel ref="formPanel"
                v-bind="submitConfig"
                :form="form"
                @update="update">
      <col2-detail>
        <col2-block title="调查信息">
          <el-form-item label="调查名称">
            <span>{{ form.title }}</span>
          </el-form-item>
          <el-form-item label="调查类型">
            <span>{{ form.categoryName }}</span>
          </el-form-item>
          <el-form-item label="关联标签"
                        v-if="!isActivity">
            <span>{{ form.orgLabelName }}</span>
          </el-form-item>
          <el-form-item label="调查对象"
                        v-if="!isActivity">
            <span>{{ joinUserTypeText }}</span>
          </el-form-item>
          <el-form-item label="限制到房号"
                        v-if="!isActivity">
            <span>{{ form.limitRoom ? '限制' : '不限制' }}</span>
          </el-form-item>
          <el-form-item label="调查有效期">
            <span>{{form.beginTime}} ~ {{ form.endTime}}</span>
          </el-form-item>
          <el-form-item label="调查封面">
            <img :src="form.icoUrl"
                 width="182"
                 height="100" />
          </el-form-item>
          <el-form-item label="调查简介">
            <div v-html="form.introduce"
                 style="max-height:300px;overflow:auto"></div>
          </el-form-item>
          <el-form-item label="调查协议">
            <div v-html="form.voteRule"
                 style="max-height:300px;overflow:auto"></div>
          </el-form-item>
          <el-form-item label="参与人数">
            <span>{{ form.joinUserCnt }}</span>
          </el-form-item>
          <el-form-item label="其中作废">
            <span>{{ form.invalidCount }}</span>
          </el-form-item>
        </col2-block>
      </col2-detail>
      <col2-detail>
        <col2-block title="问题信息">
          <div class="question-wrapper"
               v-for="(question, index) in questions"
               :key="index">
            <div class="question">
              <span class="questionDes"> 投票问题{{ index + 1}}、{{ question.questionDes }}</span>
              <span class="">{{ optionTypeMap[question.optionType] }}，累计{{ question.totalCnt}}票</span>
            </div>
            <div class="option"
                 v-for="(option,idx) in question.options"
                 :key="idx"
                 @click="getParticipationByOption(option)">
              <span>选项{{ idx + 1 }}：{{ option.optionDes}}</span>
              <div class="progress">
                <span class="progress-bar"
                      :style="{width:option.percent,background:colors[idx%4]}"></span>
                <span class="agree">{{ option.agreeCnt }}票,占比{{ option.percent }}</span>
              </div>
            </div>
          </div>
        </col2-block>
      </col2-detail>
      <template #footerSlot>
        <v-button text="导出结果"
                  type="success"
                  @click="exportResult"></v-button>
      </template>
    </form-panel>

    <el-dialog title="查看参与人"
               :visible.sync="participationVisible"
               width="900px">
      <el-row>
        <el-col :span="12"
                v-for="(participation,index) in participationList"
                :key="index">
          <div class="participation-wrapper">
            <div class="header"
                 :style="{ 'backgroundImage': 'url(' +participation.userHeadPic + ')'}"></div>
            <div class="info">
              <div><b>{{ participation.userName }}</b><span class="moble">{{ participation.userPhone }}</span><span>{{ participation.createTime }}</span> </div>
              <div>{{ participation.userAddress }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
import { queryURL, exportResultURL, getParticipationByOptionURL, exportLimitURL } from './api'
import { optionTypeMap } from './map'
import { joinUserTypeCompanyMap, joinUserTypeCampusMap, joinUserTypeHouseMap } from '../map'
import { Col2Detail, Col2Block } from 'components/bussiness'

export default {
  name: 'surveyResult',
  components: {
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      optionTypeMap: optionTypeMap,
      form: {
        id: undefined,
        title: '',
        categoryName: '',
        orgLabelName: '',
        icoUrl: '',
        beginTime: '',
        endTime: '',
        communityNamesTxt: '',
        introduce: '',
        voteRule: '',
        joinUserCnt: '',
        invalidCount: '',
        limitRoom: undefined
      },
      questions: [],
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: ''
      },
      colors: ['#c2e6ff', '#ffdd81', '#b2e7e8', '#ffab9d'],
      participationVisible: false,
      participationList: [],
      isActivity: false,
      joinUserTypeText: ''
    }
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('查看结果')
      this.$refs.formPanel.getData({ id })
    }
  },
  methods: {
    update (data) {
      if (data.questions.length > 0) {
        data.questions.forEach(question => {
          if (question.options.length > 0) {
            question.options.forEach(option => {
              let percent = parseInt(question.totalCnt) <= 0 ? 0 : (parseInt(option.agreeCnt) / parseInt(question.totalCnt))
              option.percent = percent <= 0 ? '0%' : ((percent * 100).toFixed(2) + '%')
            })
          }
        })
      }
      this.questions = data.questions
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
      this.isActivity = data.categoryName === '活动调查'
      // 回显调查对象
      if (data.orgLabelId === 1) {
        this.joinUserTypeText = String(data.joinUserType).split('').map(key => joinUserTypeCompanyMap[key]).join(',')
      } else if (data.orgLabelId === 2) {
        console.log(String(data.joinUserType).split('').map(key => joinUserTypeHouseMap[key]))
        this.joinUserTypeText = String(data.joinUserType).split('').map(key => joinUserTypeHouseMap[key]).join(',')
      } else if (data.orgLabelId === 3) {
        this.joinUserTypeText = String(data.joinUserType).split('').map(key => joinUserTypeCampusMap[key]).join(',')
      }
    },
    // 获取该选项参与投票
    getParticipationByOption (option) {
      let _this_ = this
      if (option.agreeCnt > 0) {
        _this_.$axios.get(getParticipationByOptionURL + _this_.$qs.stringify({ id: option.id })).then(res => {
          if (res.status === 100) {
            _this_.participationList = res.data
            _this_.participationVisible = true
          }
        })
      }
    },
    exportResult () {
      let _this_ = this
      _this_.$axios.get(exportLimitURL).then(res => {
        if (res.status === 100) {
          _this_.$axios.get(exportResultURL + _this_.$qs.stringify({ voteId: _this_.$route.query.id })).then(res => {
            if (res.status === 100) {
              _this_.$message({
                type: 'success',
                message: '导出成功，请稍候前往导出中心查看进度',
                center: true
              })
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.surveyResult-container {
  .question-wrapper {
    margin-left: 88px;
    width: 700px;
    .question {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      color: #333333;
      margin: 15px 0;
      .questionDes {
        flex: 1;
        margin-right: 80px;
      }
    }
    .option {
      margin-bottom: 15px;
      font-size: 14px;
      .progress {
        margin-top: 8px;
        height: 35px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        background-color: #f8f8f8;
        position: relative;
        .progress-bar {
          float: left;
          width: 0;
          height: 100%;
          font-size: 12px;
          line-height: 20px;
          color: #fff;
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
          text-align: center;
        }
        .agree {
          position: absolute;
          top: 0;
          right: 0;
          display: inline-block;
          line-height: 35px;
          margin: 0 16px;
          z-index: 22;
        }
      }
    }
  }
  .participation-wrapper {
    display: flex;
    margin-bottom: 10px;
    .header {
      height: 40px;
      width: 40px;
      border-radius: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .info {
      margin-left: 10px;
      text-align: left;
      .moble {
        margin: 0 8px;
      }
    }
  }
}
</style>
