
// 查询详情
const queryURL = `${API_CONFIG.butlerBaseURL}questionnaire/result`
// 获取选项的参与投票的人
const getParticipationByOptionURL = `${API_CONFIG.butlerBaseURL}questionnaire/joinuser/byoption?`
// 导出结果
const exportLimitURL = `${API_CONFIG.butlerBaseURL}export/limit`
// 导出结果
const exportResultURL = `${API_CONFIG.butlerBaseURL}questionnaire/joinuser/list/export?`

export {
  queryURL,
  exportResultURL,
  getParticipationByOptionURL,
  exportLimitURL
}
