var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "surveyResult-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form },
            on: { update: _vm.update },
            scopedSlots: _vm._u([
              {
                key: "footerSlot",
                fn: function () {
                  return [
                    _c("v-button", {
                      attrs: { text: "导出结果", type: "success" },
                      on: { click: _vm.exportResult },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "调查信息" } },
                [
                  _c("el-form-item", { attrs: { label: "调查名称" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.form.title))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "调查类型" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.form.categoryName))]),
                  ]),
                  !_vm.isActivity
                    ? _c("el-form-item", { attrs: { label: "关联标签" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.orgLabelName))]),
                      ])
                    : _vm._e(),
                  !_vm.isActivity
                    ? _c("el-form-item", { attrs: { label: "调查对象" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.joinUserTypeText))]),
                      ])
                    : _vm._e(),
                  !_vm.isActivity
                    ? _c("el-form-item", { attrs: { label: "限制到房号" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.form.limitRoom ? "限制" : "不限制")
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("el-form-item", { attrs: { label: "调查有效期" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.form.beginTime) +
                          " ~ " +
                          _vm._s(_vm.form.endTime)
                      ),
                    ]),
                  ]),
                  _c("el-form-item", { attrs: { label: "调查封面" } }, [
                    _c("img", {
                      attrs: {
                        src: _vm.form.icoUrl,
                        width: "182",
                        height: "100",
                      },
                    }),
                  ]),
                  _c("el-form-item", { attrs: { label: "调查简介" } }, [
                    _c("div", {
                      staticStyle: { "max-height": "300px", overflow: "auto" },
                      domProps: { innerHTML: _vm._s(_vm.form.introduce) },
                    }),
                  ]),
                  _c("el-form-item", { attrs: { label: "调查协议" } }, [
                    _c("div", {
                      staticStyle: { "max-height": "300px", overflow: "auto" },
                      domProps: { innerHTML: _vm._s(_vm.form.voteRule) },
                    }),
                  ]),
                  _c("el-form-item", { attrs: { label: "参与人数" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.form.joinUserCnt))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "其中作废" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.form.invalidCount))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "问题信息" } },
                _vm._l(_vm.questions, function (question, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "question-wrapper" },
                    [
                      _c("div", { staticClass: "question" }, [
                        _c("span", { staticClass: "questionDes" }, [
                          _vm._v(
                            " 投票问题" +
                              _vm._s(index + 1) +
                              "、" +
                              _vm._s(question.questionDes)
                          ),
                        ]),
                        _c("span", {}, [
                          _vm._v(
                            _vm._s(_vm.optionTypeMap[question.optionType]) +
                              "，累计" +
                              _vm._s(question.totalCnt) +
                              "票"
                          ),
                        ]),
                      ]),
                      _vm._l(question.options, function (option, idx) {
                        return _c(
                          "div",
                          {
                            key: idx,
                            staticClass: "option",
                            on: {
                              click: function ($event) {
                                return _vm.getParticipationByOption(option)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "选项" +
                                  _vm._s(idx + 1) +
                                  "：" +
                                  _vm._s(option.optionDes)
                              ),
                            ]),
                            _c("div", { staticClass: "progress" }, [
                              _c("span", {
                                staticClass: "progress-bar",
                                style: {
                                  width: option.percent,
                                  background: _vm.colors[idx % 4],
                                },
                              }),
                              _c("span", { staticClass: "agree" }, [
                                _vm._v(
                                  _vm._s(option.agreeCnt) +
                                    "票,占比" +
                                    _vm._s(option.percent)
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看参与人",
            visible: _vm.participationVisible,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.participationVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            _vm._l(_vm.participationList, function (participation, index) {
              return _c("el-col", { key: index, attrs: { span: 12 } }, [
                _c("div", { staticClass: "participation-wrapper" }, [
                  _c("div", {
                    staticClass: "header",
                    style: {
                      backgroundImage: "url(" + participation.userHeadPic + ")",
                    },
                  }),
                  _c("div", { staticClass: "info" }, [
                    _c("div", [
                      _c("b", [_vm._v(_vm._s(participation.userName))]),
                      _c("span", { staticClass: "moble" }, [
                        _vm._v(_vm._s(participation.userPhone)),
                      ]),
                      _c("span", [_vm._v(_vm._s(participation.createTime))]),
                    ]),
                    _c("div", [_vm._v(_vm._s(participation.userAddress))]),
                  ]),
                ]),
              ])
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }