import { generateMapByOpts } from 'common/utils'

// 选项
const optionTypeOps = [
  {
    text: '单选',
    value: 1
  },
  {
    text: '多选',
    value: 2
  },
  {
    text: '填空题',
    value: 3
  }
]

const optionTypeMap = generateMapByOpts(optionTypeOps)

export {
  optionTypeOps,
  optionTypeMap
}
